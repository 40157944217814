<template>
  <!-- <transition-group
    appear
    leave-active-class="animated slideOutUp"
    enter-active-class="animated slideInDown"
  > -->
  <div class="ageVerification" key="ageVerification">
    <div class="avLeft">
      <div class="avOverlay"> </div>
    </div>
    <div class="avRight text-center">
      <q-form @submit="onSubmit">
        <q-stepper
          v-model="step"
          ref="stepper"
          color="primaryOnBody"
          header-nav
          alternative-labels
          contracted
          animated
          class="authSteps no-hover"
        >
          <q-step
            :name="1"
            title="Step 1"
            icon="manage_accounts"
            :done="step > 1"
          >
            <img
              v-if="theme && theme.logoURL"
              :style="
                !$q.screen.lt.md
                  ? 'max-width:100%; width:auto; max-height:60px;'
                  : ' max-width:100%; width:auto; max-height:40px;'
              "
              :src="theme.logoURL"
              class="inline-block vertical-middle"
            />
            <label
              v-else
              class="q-my-none text-h6 text-weight-bold bg-white q-py-sm q-px-md r-8 s-1"
              v-text="businessConfigurations.businessName || ''"
            />
            <AgeVerificationInfo />
          </q-step>

          <!-- <q-step
              :name="2"
              title="Step 2"
              icon="manage_accounts"
              :done="step > 2"
            >
              <div class="avForm">
                <div class="row q-col-gutter-md justify-center">
                  <div class="col-12">
                    <div class="ageField">
                      <div class="box"></div>
                      <div class="box ageBox">21<span>+</span></div>
                      <div class="box"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="text-h5 text-weight-bold">
                      Age Verification
                    </div>
                  </div>
                  <div class="col-12 q-py-none">
                    <div class="text-body1">
                      Please verify below details as per your driver's license
                      and continue with Age Verification process
                    </div>

                    <q-scroll-area
                      class="ageVerificationHeight"
                      :thumb-style="scrollAreaThumbStyle"
                      :bar-style="scrollAreaBarStyle"
                      :visible="scrollAreaVisible"
                      style="max-width: 100%"
                    >
                      <div class="row q-col-gutter-md q-mt-md">
                        <div class="col-6">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="First Name*"
                              v-model="personalInfo.firstName"
                              :rules="[
                                validateRequired,
                                validatePersonName(
                                  personalInfo.firstName,
                                  'Please enter a valid name'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="40"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="Last Name*"
                              v-model="personalInfo.lastName"
                              :rules="[
                                validateRequired,
                                validatePersonName(
                                  personalInfo.lastName,
                                  'Please enter a valid name'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="40"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              v-model="personalInfo.dob"
                              mask="##/##/####"
                              label="Date of Birth*"
                              placeholder="MM/DD/YYYY"
                              :rules="[validateRequired, validateFullDOB]"
                              :lazy-rules="lazyValidationRules"
                              aria-label="ftxcommerce"
                              @input="onChangeDOB"
                            >
                              <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy
                                    ref="qDateProxy"
                                    transition-show="scale"
                                    transition-hide="scale"
                                  >
                                    <q-date
                                      v-model="configureDOB"
                                      mask="MM/DD/YYYY"
                                      :options="isDateAllowed"
                                    >
                                      <div
                                        class="row items-center justify-end q-gutter-sm"
                                      >
                                        <q-btn
                                          label="Cancel"
                                          color="primary"
                                          flat
                                          v-close-popup
                                          @click="cancelDateSelection"
                                        />
                                        <q-btn
                                          label="OK"
                                          color="primary"
                                          flat
                                          v-close-popup
                                          @click="selectDate"
                                        />
                                      </div>
                                    </q-date>
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                            </q-input>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-field pb-0">
                            <GoogleAutoComplete
                              :outlined="true"
                              label="Address Line 1*"
                              :addressFieldChange="addressFieldChange"
                              modelField="addressLine1"
                              :value="personalInfo.addressLine1"
                              :placechanged="getAddressData"
                              :rules="[validateRequired]"
                              :lazy-rules="lazyValidationRules"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="Address Line 2"
                              v-model="personalInfo.addressLine2"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="City*"
                              v-model="personalInfo.city"
                              :rules="[
                                validateRequired,
                                validatePersonName(
                                  personalInfo.city,
                                  'Please enter valid city'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="State*"
                              v-model="personalInfo.state"
                              :rules="[
                                validateRequired,
                                validatePersonName(
                                  personalInfo.state,
                                  'Please enter valid state'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="Zip Code*"
                              v-model="personalInfo.zip"
                              v-mask="'#####'"
                              :rules="[validateZip]"
                              :lazy-rules="lazyValidationRules"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="Phone Number*"
                              v-model="personalInfo.phone"
                              v-mask="phoneMask"
                              :rules="[validateRequired, validatePhone()]"
                              :lazy-rules="lazyValidationRules"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-field pb-0">
                            <q-input
                              outlined
                              label="Email Address"
                              v-model.trim="personalInfo.email"
                              :rules="[validateEmail]"
                              :lazy-rules="lazyValidationRules"
                              aria-label="ftxcommerce"
                            />
                          </div>
                        </div>
                      </div>
                    </q-scroll-area>
                    <div class="q-px-md">
                      <div class="row q-col-gutter-md q-mt-xs">
                        <div class="col-6">
                          <div class="form-action pb-0">
                            <q-btn
                              unelevated
                              no-caps
                              color="tertiary"
                              label="Go Back"
                              class="q-btn-lg full-width"
                              @click="$refs.stepper.previous()"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-action pb-0">
                            <q-btn
                              unelevated
                              no-caps
                              color="secondary"
                              label="Continue"
                              class="q-btn-lg full-width"
                              type="submit"
                              :loading="loading"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </q-step> -->
          <q-step
            :name="2"
            title="Step 2"
            icon="manage_accounts"
            :done="step > 2"
          >
            <div class="avForm">
              <div class="row q-col-gutter-md justify-center">
                <div class="col-12">
                  <div class="row q-col-gutter-md">
                    <div class="col-12 col-md-12 q-pt-sm text-center">
                      <q-avatar
                        size="80px"
                        font-size="42px"
                        color="green"
                        text-color="white"
                        icon="check"
                        class="q-mb-sm"
                      />
                      <h5 class="text-h5 q-my-none q-mb-sm text-positive">
                        Success!
                      </h5>
                      <p class="text-body1 text-grey">
                        Thanks! You have been successfully verified.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-10 col-lg-9">
                  <div class="row q-col-gutter-md">
                    <div class="col-12">
                      <div class="form-action pb-0">
                        <q-btn
                          unelevated
                          no-caps
                          color="green"
                          label="Continue"
                          class="q-btn-lg full-width"
                          @click="hidePage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-step>
          <q-step
            :name="3"
            title="Step 3"
            icon="manage_accounts"
            :done="step > 3"
          >
            <div class="avForm">
              <div class="row q-col-gutter-md justify-center">
                <div class="col-12">
                  <div class="row q-col-gutter-md">
                    <div class="col-12 col-md-12 q-pt-sm text-center">
                      <q-avatar
                        size="80px"
                        font-size="42px"
                        color="red"
                        text-color="white"
                        icon="close"
                        class="q-mb-sm"
                      />
                      <h5 class="text-h5 q-my-none q-mb-sm text-red">
                        Age Verification Failed!
                      </h5>
                      <p class="text-body1 text-grey">
                        Unfortunately, your age verification process is failed!
                        Click "Retry" to verify your age or "Continue" to access
                        the products that don't require age verification.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-10 col-lg-9">
                  <div class="row q-col-gutter-md">
                    <div class="col-12 col-md-6">
                      <div class="form-action pb-0">
                        <q-btn
                          unelevated
                          no-caps
                          outline
                          color="tertiary"
                          label="Continue"
                          class="q-btn-lg full-width"
                          v-close-popup
                          @click="hidePage"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-action pb-0">
                        <q-btn
                          unelevated
                          no-caps
                          color="secondary"
                          label="Retry"
                          class="q-btn-lg full-width"
                          @click="$refs.stepper.goTo(1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-step>
        </q-stepper>
      </q-form>
    </div>
  </div>
  <!-- </transition-group> -->
</template>

<script>
import { mapGetters } from 'vuex'

let addressTypeMaper = {
  street_number: {
    get: 'short_name',
    set: 'addressLine1',
  },
  route: {
    get: 'long_name',
    set: 'addressLine1',
  },
  locality: {
    get: 'long_name',
    set: 'city',
  },
  administrative_area_level_1: {
    get: 'short_name',
    set: 'state',
  },
  sublocality_level_1: {
    get: 'long_name',
    set: 'addressLine2',
  },
  sublocality_level_2: {
    get: 'long_name',
    set: 'addressLine2',
  },
  sublocality_level_3: {
    get: 'long_name',
    set: 'addressLine2',
  },
  postal_code: { get: 'short_name', set: 'zip' },
}

export default {
  name: 'AgeVerificationPage',
  data() {
    return {
      step: 1,
      personalInfo: {
        firstName: '',
        lastName: '',
        dob: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        countryCode: '+1',
      },
      birthDate: '',
      loading: false,
    }
  },
  components: {
    AgeVerificationInfo: () =>
      import('components/dialog/eaiv/AgeVerificationInfo'),
    // GoogleAutoComplete: () => import('components/common/GoogleAutoComplete'),
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('ageVerification', [
      'isVerificationSuccess',
      'isVerificationFail',
    ]),
    ...mapGetters('persisted', ['showEAIVPage']),
    configureDOB: {
      get() {
        return this.birthDate
      },
      set(val) {
        this.birthDate = val
      },
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  mounted() {
    // if (this.showEAIVPage && this.isVerificationFail) this.step = 4
    if (this.showEAIVPage) {
      if (this.isVerificationSuccess) {
        this.$store.commit('ageVerification/SET_LOADING', false)
        this.$store.commit('ageVerification/RESET_VERIFICATION_STATUS', false)
        this.step = 2
      }
      if (this.isVerificationFail) {
        this.$store.commit('ageVerification/SET_LOADING', false)
        this.$store.commit('ageVerification/RESET_VERIFICATION_STATUS', false)
        this.step = 3
      }
    }
  },
  watch: {
    isVerificationSuccess(newVal, oldVal) {
      if (this.showEAIVPage && newVal === true && newVal != oldVal) {
        this.$store.commit('ageVerification/SET_LOADING', false)
        this.$store.commit('ageVerification/RESET_VERIFICATION_STATUS', false)
        this.step = 2
      }
    },
    isVerificationFail(newVal, oldVal) {
      if (this.showEAIVPage && newVal === true && newVal != oldVal) {
        this.$store.commit('ageVerification/SET_LOADING', false)
        this.$store.commit('ageVerification/RESET_VERIFICATION_STATUS', false)
        this.step = 3
      }
    },
  },
  methods: {
    addressFieldChange(field, value) {
      if (this.personalInfo.hasOwnProperty(field)) {
        this.personalInfo[field] = value
      }
    },
    async getAddressData({ addressData, geoLocation, place_id }) {
      Object.keys(addressTypeMaper).forEach((key) => {
        this.addressFieldChange(addressTypeMaper[key].set, '')
      })

      // this.addressFieldChange('line2', '')

      addressData.forEach((addressComponents) => {
        const addressType = addressComponents.types[0]
        if (addressTypeMaper[addressType]) {
          const val = addressComponents[addressTypeMaper[addressType]['get']]

          this.addressFieldChange(
            addressTypeMaper[addressType]['set'],
            this.personalInfo[addressTypeMaper[addressType]['set']]
              ? `${
                  this.personalInfo[addressTypeMaper[addressType]['set']]
                } ${val}`
              : val
          )
        }
      })

      if (geoLocation && geoLocation.latitude && geoLocation.longitude) {
        const geoLocationDetails = `${geoLocation.latitude},${geoLocation.longitude}`

        this.addressFieldChange('geoLocation', geoLocationDetails)
      }
    },
    isDateAllowed(date) {
      return new Date().toISOString().slice(0, 10).split('-').join('/') >= date
    },
    getFormattedDate(val) {
      if (!val) return val
      let date = val.replace(
        /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
        '$3-$1-$2'
      )
      return date
    },
    isValidDate(val) {
      return /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.test(val)
    },
    onChangeDOB(val) {
      let isValid = this.isValidDate(val)
      if (isValid) this.configureDOB = this.personalInfo.dob
    },
    selectDate() {
      this.personalInfo.dob = this.configureDOB
    },
    cancelDateSelection() {
      this.configureDOB = this.personalInfo.dob
    },
    validateRequiredIf(val, field) {
      if (field && this.personalInfo[field]) {
        return true
      } else {
        return !!val || 'Field is required'
      }
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    navigateToProfilePage() {
      const _this = this,
        options = this.isCordova
          ? 'location=no,clearsessioncache=no,clearcache=no,cleardata=no'
          : ''
      const popupWindow = window.open(this.myProfileUrl, '_blank', options)
      if (this.isCordova)
        popupWindow.addEventListener('exit', function (event) {
          _this.$store.dispatch('auth/getUserInfo')
        })
    },
    onSubmit() {
      if (this.step == 2) {
        this.loading = true
        let dob = this.getFormattedDate(this.personalInfo.dob)
        let reqData = {
          personalInfo: { ...this.personalInfo, dob },
          redirectUri: this.getRedirectUri,
          verificationDuring: 0,
          customerID: this.existingCustomerId || null,
        }
        if (reqData.personalInfo.phone)
          reqData.personalInfo.phone = this.unmask(
            reqData.personalInfo.phone,
            this.phoneMask
          )

        this.$store
          .dispatch('ageVerification/sendPersonalInfo', reqData)
          .then((response) => {
            if (response.success && response.data && response.data?.sessionId) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!response.success && response.message) {
              this.showError(response.message)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    hidePage() {
      this.$store.commit('persisted/SET_SHOW_EAIV_PAGE', false)
    },
  },
}
</script>

<style lang="scss">
.ageVerification {
  height: 100%;
  display: flex;
  flex-direction: column;
  .avRight {
    height: 100%;
  }
  .avLeft {
    background: url(/images/avbg.jpg) center center no-repeat;
    background-size: cover;
  }
  .avOverlay {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.17);
    /* -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px); */
  }
  .q-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    height: 100%;
    overflow: auto;
    margin: auto;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .avLeft,
    .avRight {
      flex: 1 0 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 1023px) {
    .avForm {
      padding: 15px 0;
    }
    .avLeft {
      min-height: 100px;
    }
    .avRight {
      /* height: auto; */
      flex: 1 1 auto;
      overflow-y: auto;
      min-height: 0px;
    }
  }
  @media (max-width: 479px) {
    .age21plus {
      .q-btn__wrapper {
        padding: 30px 20px !important;
      }
    }
  }
}

.authSteps {
  &.q-stepper--horizontal {
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: auto;
    margin-bottom: auto;
    .q-stepper__header {
      justify-content: center;
      background: none;
      border: none;
      min-height: 12px;
      display: none;
    }
    .q-stepper__tab {
      padding: 0px 5px 10px;
      background: none;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      border: none;
      box-shadow: none;
      align-items: center !important;
      min-height: 12px;
      .q-stepper__dot {
        font-size: 18px;
        min-width: 12px;
        width: 12px;
        height: 12px;
        line-height: 12px;
        .q-icon {
          display: none;
          width: 1.3rem;
          height: 1.3rem;
        }
      }
      &:first-child {
        .q-stepper__dot {
          transform: translateX(90px);
        }
      }
      &:last-child {
        .q-stepper__dot {
          transform: translateX(-90px);
        }
      }
    }
    .q-stepper__label {
      display: none;
      flex-direction: column;
      .q-stepper__caption {
        order: 1;
      }
      .q-stepper__title {
        order: 2;
        border: none;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .q-stepper__dot {
      display: flex;
    }
    .q-stepper__tab--active {
    }
    .q-stepper__step-inner {
      padding: 0 15px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .q-stepper__nav {
      padding: 0 15px 0;
    }
  }

  .authBack {
    position: absolute;
    left: -20px;
    top: -130px;
    @media (max-width: 991px) {
      left: -10px;
      top: -125px;
    }
  }
}

.ageVerificationHeight {
  padding: 0 16px;
  height: 465px;
  max-height: calc(100vh - 385px);
  @media (min-width: 1024px) {
    max-height: calc(100vh - 310px);
  }
}
</style>
